import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SimpleFileUpload from 'react-simple-file-upload'
import { Header } from '../../components'
import '../../pages/NewPages/Map/MapBox.css'
import { BASE_URL, get, post, put } from '../../Services/Calls'
import './NewMain.css'

import DeleteIcon from '../../data/deleteIcon.png'
import DropDownArrow from '../../data/rightArrow.png'
import { mapboxglAccessToken, notify, SimpleFileUploadAPIKey } from '../../Services/Utils'


mapboxgl.accessToken = mapboxglAccessToken;

const CreateAttraction = () => {
    const navigate = useNavigate()
    const location = useLocation()


    const [attractionName, setAttractionName] = useState('')
    const [imageName, setImageName] = useState('')
    const [about, setAbout] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [fromStatus, setFromStatus] = useState('')
    const [attractionId, setAttractionId] = useState('')
    const [dayIdArray, setDayIdArray] = useState([])
    const [selectedRecords, setSelectedRecords] = useState([])
    const [showImagePopUp, setShowImagePopUp] = useState(false);

    const [attractionProviderEmail, setAttractionProviderEmail] = useState("");
    const [attractionProviderAddress, setAttractionProviderAddress] = useState("");
    const [attractionProviderPhone, setAttractionProviderPhone] = useState("");
    const [attractionProviderType, setAttractionProviderType] = useState(0)

    const [confirmationType, setConfirmationType] = useState(1)
    const [localHeroEmail, setLocalHeroEmail] = useState("");
    const [localHeroAddress, setLocalHeroAddress] = useState("");
    const [localHeroPhone, setLocalHeroPhone] = useState("");

    const [whatToRemember, setWhatToRemember] = useState('');
    const [meetingPoint, setMeetingPoint] = useState('');
    const [extraInformation, setExtraInformation] = useState('');
    const [duration, setDuration] = useState('');
    const [cancellationFee, setCancellationFee] = useState('');
    const [netPrice, setNetPrice] = useState('');

    const [showLanguageSelect, setShowLanguageSelect] = useState(false);
    const [showCountrySelect, setShowCountrySelect] = useState(false);
    const [showGoupStructure, setShowGoupStructure] = useState(false);

    const [attractionLat, setAttractionLat] = useState('')
    const [attractionLong, setAttractionLong] = useState('')

    const [map, setMap] = useState(null)
    const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11')
    const [mapMode, setMapMode] = useState('1')

    const mapRef = useRef(null)
    const markerRef = useRef(null)

    const gridRef = useRef(null);
    const [currencyData, setCurrencyData] = useState([])
    const [languageData, setLanguageData] = useState([])
    const [countryData, setCountryData] = useState([])
    const [country, setCountry] = useState({})
    const [currency, setCurrency] = useState({})
    const [language, setLanguage] = useState({})
    const [groupStructure, setGroupStructure] = useState("")
    const [languageSelectedArray, setLanguageSelectedArray] = useState([])
    const [languageIdArray, setLanguageIdArray] = useState([])

    const [dayHours, setDayHours] = useState([
        {
            "day": "SUN",
            "from_time": "",
            "to_time": ""
        },
        {
            "day": "MON",
            "from_time": "",
            "to_time": ""
        },
        {
            "day": "TUE",
            "from_time": "",
            "to_time": ""
        },
        {
            "day": "WED",
            "from_time": "",
            "to_time": ""
        },
        {
            "day": "THU",
            "from_time": "",
            "to_time": ""
        },
        {
            "day": "FRI",
            "from_time": "",
            "to_time": ""
        },
        {
            "day": "SAT",
            "from_time": "",
            "to_time": ""
        },
    ])

    const [ticketTypes, setTicketTypes] = useState([
        {
            "type": "",
            "price": "",
            "currency": ""
        },
    ])

    useEffect(() => {
        (async () => {
            await getCurrency()
        })();
    }, [])

    const getCurrency = async () => {
        try {
            const response = await get(BASE_URL + "/currencies");

            if (response && response.length > 0) {
                setCurrencyData(response)
            }
        }
        catch (ex) {
            alert(ex)
        }
    };

    const getLanguage = async () => {
        try {
            const response = await get(BASE_URL + "/languages");

            if (response && response.length > 0) {
                setLanguageData(response)
            }
        }
        catch (ex) {
            alert(ex)
        }
    };

    const getLanguageEdit = async (editLanguageId) => {
        try {
            const response = await get(BASE_URL + "/languages");

            if (response && response.length > 0) {
                for (let i = 0; i < response.length; i++) {
                    if (response[i].id == editLanguageId) {
                        setLanguage(response[i])
                    }
                }
                setLanguageData(response)
            }
        }
        catch (ex) {
            alert(ex)
        }
    };

    const getCountries = async () => {
        try {
            const response = await get(BASE_URL + '/countries?order=ASC');
            if (response && response.length > 0) {
                setCountryData(response);
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    useEffect(() => {
        (async () => {
            await getCountries();
        })();

        // Create a map instance
        mapRef.current = new mapboxgl.Map({
            container: 'map', // Specify the container ID
            style: mapStyle, // Specify the map style
            center: [74.5, 40], // Specify the starting center
            zoom: 1, // Specify the starting zoom
        })
        mapRef.current.on('load', () => {
            setMap(mapRef.current)
        })
        mapRef.current.on('style.load', function () {
            mapRef.current.on('click', function (e) {
                var coordinates = e.lngLat
                new mapboxgl.Popup()
                if (markerRef.current != null) {
                    markerRef.current.remove()
                }
                markerRef.current = new mapboxgl.Marker()
                    .setLngLat(coordinates)
                    // .setHTML('' + coordinates)
                    .addTo(mapRef.current)

                setAttractionLat(coordinates.lat)
                setAttractionLong(coordinates.lng)
                mapRef.current.flyTo({ center: [coordinates.lng, coordinates.lat], zoom: 7 });
            })
        })
        // Clean up the map instance when the component unmounts
        return () => mapRef.current.remove()
    }, [mapStyle, mapMode]) // The empty dependency array ensures this useEffect runs only once

    useEffect(() => {

        if (location && location.state && location.state.from) {
            setFromStatus(location.state.from)
            if (location.state.from == 'create') {
                getLanguage()
            }
            if (location.state.data && location.state.data.id) {
                setAttractionName(location.state.data.name)
                setAttractionLat(location.state.data.latitude)
                setAttractionLong(location.state.data.longitude)
                setAbout(location.state.data.about)

                setAttractionProviderEmail(location.state.data.attraction_provider.email)
                setAttractionProviderAddress(location.state.data.attraction_provider.address)
                setAttractionProviderPhone(location.state.data.attraction_provider.phone)
                setAttractionProviderType(location.state.data.attraction_provider.type)
                setDayHours(location.state.data.attraction_provider.availability_slots)

                setCancellationFee(location.state.data.cancellation_fee_desc)
                setLocalHeroEmail(location.state.data.confirmation.email)
                setLocalHeroAddress(location.state.data.confirmation.address)
                setLocalHeroPhone(location.state.data.confirmation.phone)

                setConfirmationType(location.state.data.confirmation_type)
                setDuration(location.state.data.duration)
                setExtraInformation(location.state.data.extra_data)
                setGroupStructure(location.state.data.group_structure)

                setImageName(location.state.data.main_photo_url)
                setMeetingPoint(location.state.data.meeting_point)
                setNetPrice(location.state.data.net_price)
                setTicketTypes(location.state.data.ticket_types)
                setWhatToRemember(location.state.data.what_to_remember)

                setAttractionId(location.state.data.id)

                getLanguageEdit(location.state.data.language)

                markerRef.current = new mapboxgl.Marker()
                    .setLngLat([location.state.data.longitude, location.state.data.latitude])
                    .addTo(mapRef.current);
            }
        }
        window.scrollTo(0, 0)
    }, [])

    async function createAttractionsFun() {
        if (attractionName == '' || attractionName == undefined) {
            setErrorMsg('Please Enter Attraction Name.')
            return
        }
        if (about == '' || about == undefined) {
            setErrorMsg('Please Enter Attraction About.')
            return
        }
        if (imageName && imageName != '') { }
        else {
            setErrorMsg('Please Upload Attraction Image.')
            return;
        }




        try {
            let json = {
                name: attractionName,
                location_point: {
                    latitude: attractionLat,
                    longitude: attractionLong
                },
                attraction_provider: {
                    availability_slots: dayHours,
                    email: attractionProviderEmail,
                    address: attractionProviderAddress,
                    phone: attractionProviderPhone,
                    type: attractionProviderType
                },
                confirmation: {
                    id: 0,
                    email: localHeroEmail,
                    address: localHeroAddress,
                    phone: localHeroPhone
                },
                ticket_types: ticketTypes,
                main_photo_url: imageName,
                about: about,
                group_structure: groupStructure,
                language: language.id,
                what_to_remember: whatToRemember,
                meeting_point: meetingPoint,
                duration: duration,
                cancellation_fee_desc: cancellationFee,
                extra_data: extraInformation,
                net_price: netPrice,
                confirmation_type: confirmationType,
                country: country.id,
            }

            const response = await post(BASE_URL + '/attractions', json)

            if (response.status == 201) {
                notify('Attraction created successfully');
                navigate('/attractions');
            }
            //   else{
            //  alert("please fill valid value in form or something went wrong, try again later.")
            //   }
        } catch (ex) {
            alert(ex)
        }
    }

    async function editTripFun() {
        if (attractionName == '' || attractionName == undefined) {
            setErrorMsg('Please Enter Attraction Name.')
            return
        }
        if (about == '' || about == undefined) {
            setErrorMsg('Please Enter Attraction About.')
            return
        }
        if (imageName && imageName != '') { }
        else {
            setErrorMsg('Please Upload Attraction Image.')
            return;
        }




        try {
            let json = {
                name: attractionName,
                location_point: {
                    latitude: attractionLat,
                    longitude: attractionLong
                },
                attraction_provider: JSON.stringify({
                    availability_slots: dayHours,
                    email: attractionProviderEmail,
                    address: attractionProviderAddress,
                    phone: attractionProviderPhone,
                    type: attractionProviderType
                }),
                confirmation: JSON.stringify({
                    id: 0,
                    email: localHeroEmail,
                    address: localHeroAddress,
                    phone: localHeroPhone
                }),
                ticket_types: JSON.stringify(ticketTypes),
                main_photo_url: imageName,
                about: about,
                group_structure: groupStructure,
                language: language.id,
                what_to_remember: whatToRemember,
                meeting_point: meetingPoint,
                duration: duration,
                cancellation_fee_desc: cancellationFee,
                extra_data: extraInformation,
                net_price: netPrice,
                confirmation_type: confirmationType,
                country: country.id
            }


            const response = await put(BASE_URL + '/attractions/' + attractionId, json)

            if (response.status == 200) {
                notify('Attraction updated successfully')
                navigate('/attractions')
            }
        } catch (ex) {
            alert(ex)
        }
    }


    function handleUpload(url) {
        // url of cdn backed file returned

        setImageName(url)
        setShowImagePopUp(false)
        setErrorMsg("")
    }

    // const [tempSelRec, setTempSelRec] = useState([])

    const rowSelected = () => {
        if (gridRef && gridRef.current) {
            const tempSelectedRecords = gridRef.current.getSelectedRecords()

            let tempData = [...selectedRecords]
            let tempDayIdArray = [...dayIdArray]

            if (selectedRecords && selectedRecords.length > 0) {

                for (let i = 0; i < tempSelectedRecords.length; i++) {
                    let status = false
                    for (let j = 0; j < selectedRecords.length; j++) {
                        if (tempSelectedRecords[i].id == selectedRecords[j].id) {
                            status = true
                        }
                    }
                    if (!status) {
                        tempData.push(tempSelectedRecords[i])
                        tempDayIdArray.push(tempSelectedRecords[i].id)
                    }
                }

                setDayIdArray(tempDayIdArray)
                setSelectedRecords(tempData)
            }
            else {

                setSelectedRecords(tempSelectedRecords)
                tempDayIdArray.push(tempSelectedRecords[0].id)
                setDayIdArray(tempDayIdArray)

            }
        }
    }


    const rowDeselected = args => {
        try {

            let tempSelectedRecords = [...selectedRecords]
            for (let i = 0; i < tempSelectedRecords.length; i++) {
                if (args.data.length > 1) {
                    for (let j = 0; j < args.data.length; j++) {
                        if (tempSelectedRecords[i].id == args.data[j].id) {
                            tempSelectedRecords.splice(i, 1)
                        }
                    }
                } else {
                    if (tempSelectedRecords[i].id == args.data.id) {
                        tempSelectedRecords.splice(i, 1)
                    }
                }
            }
            setSelectedRecords(tempSelectedRecords)
            let tempIdArray = []
            for (let i = 0; i < tempSelectedRecords.length; i++) {
                tempIdArray.push(tempSelectedRecords[i].id)
            }
            setDayIdArray(tempIdArray)
        }
        catch (ex) {

        }
    }


    const onDragEnd = (result) => {
        if (!result.destination) {
            return; // Drop outside the list
        }
        const reorderedItems = Array.from(selectedRecords);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);

        setSelectedRecords(reorderedItems);
        let tempIdArray = []
        for (let i = 0; i < reorderedItems.length; i++) {
            tempIdArray.push(reorderedItems[i].id)
        }
        setDayIdArray(tempIdArray)

    };

    function selectLanguagesFun(item) {

        let tempLanguageSelectedArray = [...languageSelectedArray]
        let tempLanguageIdArray = [...languageIdArray]
        if (languageSelectedArray && languageSelectedArray.length > 0) {
            if (tempLanguageIdArray.includes(item.id)) {
                const index = tempLanguageIdArray.indexOf(item.id);
                tempLanguageSelectedArray.splice(index, 1);
                tempLanguageIdArray.splice(index, 1);
            }
            else {
                tempLanguageSelectedArray.push(item)
                tempLanguageIdArray.push(item.id)
            }
        }
        else {
            tempLanguageSelectedArray.push(item)
            tempLanguageIdArray.push(item.id)
        }
        setLanguageSelectedArray(tempLanguageSelectedArray)
        setLanguageIdArray(tempLanguageIdArray)
        // setLanguage(item);

        // setShowLanguageSelect(false)
    }

    function changeDayHours(e, item, index, status) {
        const tempArray = [...dayHours];
        if (status == 'from') {
            tempArray[index].from_time = e.target.value;
        }
        else {
            tempArray[index].to_time = e.target.value;
        }

        setDayHours(tempArray);

    }

    function addTicketType() {
        let tempTicketTypes = [...ticketTypes]
        let json = {
            "type": "",
            "price": "",
            "currency": ""
        }
        tempTicketTypes.push(json)
        setTicketTypes(tempTicketTypes)
    }
    function deleteTicketType(item, index) {
        let tempTicketTypes = [...ticketTypes]
        tempTicketTypes.splice(index, 1)
        setTicketTypes(tempTicketTypes)
    }

    function changeTicketTypes(e, item, index, status) {
        const tempTicketTypes = [...ticketTypes];
        if (status == 'type') {
            tempTicketTypes[index].type = e.target.value;
        }
        if (status == 'price') {
            tempTicketTypes[index].price = e.target.value;
        }
        if (status == 'currency') {
            tempTicketTypes[index].currency = e.target.value;
        }
        setTicketTypes(tempTicketTypes);

    }



    return (
        <div className='m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl'>
            <div className='flex justify-between items-center h-20'>
                <Header
                    category=''
                    title={fromStatus == 'create' ? 'Add New Attraction' : 'Edit Attraction'}
                />
            </div>
            <div className='countryFormMainDiv p-10'>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 100
                    }}
                >
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: 10,
                                width: '60%'
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>Name</span>
                            <input
                                type='text'
                                className='InputBoxCss'
                                style={{ width: 350 }}
                                value={attractionName}
                                onChange={e => {
                                    setAttractionName(e.target.value)
                                }}
                                onFocus={() => {
                                    setErrorMsg('')
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: 10,
                                marginTop: 40
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>About</span>
                            <textarea
                                aria-invalid='false'
                                id='txtDescription'
                                rows='5'
                                class='MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiOutlinedInput-inputMultiline InputBoxCss'
                                style={{ width: 600 }}
                                value={about}
                                onChange={e => {
                                    setAbout(e.target.value)
                                }}
                                onFocus={() => setErrorMsg('')}
                            />
                        </div>
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '40%' }}>
                        <span style={{ fontSize: 20, color: '#000' }}>Image</span>
                        {imageName && imageName != "" ?
                            <>
                                <img src={imageName} style={{ width: 250, height: 150, borderRadius: 10 }} alt="" />
                                <div className="changeImg" onClick={() => { setShowImagePopUp(true) }}>
                                    Change Image
                                </div>
                            </>
                            :
                            <SimpleFileUpload
                                apiKey={SimpleFileUploadAPIKey}
                                onSuccess={handleUpload}
                                width="350"
                            />
                        }
                    </div>
                </div>

                <div className='selectLatLongDiv'>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: 10,
                                width: '60%'
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>
                                Latitude, Longitude
                            </span>
                            <input
                                type='text'
                                className='InputBoxCss'
                                style={{ width: 350 }}
                                placeholder='32.00, 34.00'
                                value={attractionLat != '' ? attractionLat + "," + attractionLong : ''}
                                onChange={e => {
                                    let latlng = e.target.value;
                                    if (e.target.value === '') {
                                        setAttractionLat('');
                                        setAttractionLong('');
                                        if (markerRef.current != null) {
                                            markerRef.current.remove();
                                        }
                                    } else {
                                        setAttractionLat(latlng);
                                        if (markerRef.current != null) {
                                            markerRef.current.remove();
                                        }
                                    }
                                    latlng = latlng.trim();
                                    latlng = latlng.split(',');
                                    if (latlng.length === 2) {
                                        setAttractionLat(latlng[0].trim());
                                        setAttractionLong(latlng[1].trim());
                                        markerRef.current = new mapboxgl.Marker()
                                            .setLngLat([latlng[1].trim(), latlng[0].trim()])
                                            .addTo(mapRef.current);
                                        map.flyTo({ center: [latlng[1].trim(), latlng[0].trim()], zoom: 7 });
                                    }
                                }}
                                onFocus={() => setErrorMsg('')}
                            />
                        </div>
                        <div className='outerMapContainer'>
                            <div id='map' style={{ width: '100%', height: '100%' }}></div>
                        </div>
                    </div>
                    <div>
                        <div
                            style={{
                                width: '60%'
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>
                                Group Structure
                            </span>

                            <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowGoupStructure(!showGoupStructure); setErrorMsg("") }}>
                                {groupStructure && groupStructure != "" ?
                                    <span style={{ color: '#000' }}>{groupStructure}</span>
                                    :
                                    <span style={{ color: 'gray' }}>Group Structure</span>
                                }
                                <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showGoupStructure ? "rotate(270deg)" : 'rotate(90deg)' }} />
                            </div>

                            {showGoupStructure &&
                                <ClickAwayListener onClickAway={() => { setShowGoupStructure(false) }}>
                                    <div className="dropDownDiv">
                                        <div className={groupStructure == "WITH KIDS" ? "countryDropDownItemActiveDiv" : "countryDropDownItemDiv"} onClick={() => { setGroupStructure("WITH KIDS"); setShowGoupStructure(false) }}>
                                            WITH KIDS
                                        </div>
                                        <div className={groupStructure == "WITHOUT KIDS" ? "countryDropDownItemActiveDiv" : "countryDropDownItemDiv"} onClick={() => { setGroupStructure("WITHOUT KIDS"); setShowGoupStructure(false) }}>
                                            WITHOUT KIDS
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            }
                        </div>


                        <div
                            style={{
                                width: '60%',
                                marginTop: 100
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>
                                Language
                            </span>

                            <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowLanguageSelect(!showLanguageSelect); setErrorMsg("") }}>
                                {language && language.id ?
                                    <div style={{ width: '90%' }}>
                                        <span style={{ color: '#000' }}>{language.name}</span>
                                    </div>
                                    :
                                    <span style={{ color: 'gray' }}>Select Language</span>
                                }
                                <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showLanguageSelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
                            </div>

                            {showLanguageSelect &&
                                <ClickAwayListener onClickAway={() => { setShowLanguageSelect(false) }}>
                                    <div className="dropDownDiv">
                                        {languageData && languageData.length > 0 && languageData.map((item, index) => (
                                            <div className={language.id === item.id ? "countryDropDownItemActiveDiv" : "countryDropDownItemDiv"} onClick={() => { setLanguage(item); setShowLanguageSelect(false) }}>
                                                <span>{item.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </ClickAwayListener>
                            }
                        </div>

                        {location.state.from == 'create' && <div
                            style={{
                                width: '60%',
                                marginTop: 100
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>
                                Country
                            </span>

                            <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowCountrySelect(!showCountrySelect); setErrorMsg("") }}>
                                {country && country.id ?
                                    <div style={{ width: '90%' }}>
                                        <span style={{ color: '#000' }}>{country.name}</span>
                                    </div>
                                    :
                                    <span style={{ color: 'gray' }}>Select Country</span>
                                }
                                <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showCountrySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
                            </div>

                            {showCountrySelect &&
                                <ClickAwayListener onClickAway={() => { setShowCountrySelect(false) }}>
                                    <div className="dropDownDiv">
                                        {countryData && countryData.length > 0 && countryData.map((item, index) => (
                                            <div className={country.id === item.id ? "countryDropDownItemActiveDiv" : "countryDropDownItemDiv"} onClick={() => { setCountry(item); setShowCountrySelect(false) }}>
                                                <span>{item.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </ClickAwayListener>
                            }
                        </div>}

                    </div>
                </div>


                <div style={{ marginTop: 50, marginBottom: 50 }}>
                    <div style={{ fontSize: 18, fontWeight: 800, marginBottom: 15 }}>
                        ATTRACTION PROVIDER
                    </div>
                    <div style={{ padding: '0 50px', height: 'auto' }} className="selectLatLongDiv">
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    gap: 10,
                                    width: '60%'
                                }}
                            >
                                <span style={{ fontSize: 20, color: '#000' }}>Email</span>
                                <input
                                    type='text'
                                    className='InputBoxCss'
                                    style={{ width: 350 }}
                                    value={attractionProviderEmail}
                                    onChange={e => {
                                        setAttractionProviderEmail(e.target.value)
                                    }}
                                    onFocus={() => {
                                        setErrorMsg('')
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    gap: 10,
                                    width: '60%',
                                    marginTop: 30
                                }}
                            >
                                <span style={{ fontSize: 20, color: '#000' }}>Address</span>
                                <input
                                    type='text'
                                    className='InputBoxCss'
                                    style={{ width: 350 }}
                                    value={attractionProviderAddress}
                                    onChange={e => {
                                        setAttractionProviderAddress(e.target.value)
                                    }}
                                    onFocus={() => {
                                        setErrorMsg('')
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    gap: 10,
                                    width: '60%',
                                    marginTop: 30
                                }}
                            >
                                <span style={{ fontSize: 20, color: '#000' }}>Phone</span>
                                <input
                                    type='number'
                                    className='InputBoxCss'
                                    style={{ width: 350 }}
                                    value={attractionProviderPhone}
                                    onChange={e => {
                                        setAttractionProviderPhone(e.target.value)
                                    }}
                                    onFocus={() => {
                                        setErrorMsg('')
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    gap: 10,
                                }}
                            >
                                <span style={{ fontSize: 20, color: '#000' }}>Opening Days & Hours</span>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: 40
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 15
                                        }}
                                    >
                                        <div
                                            style={{
                                                border: '2px solid blue',
                                                width: 20,
                                                height: 20,
                                                borderRadius: 50,
                                                padding: 2,
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                setAttractionProviderType(1)
                                            }}
                                        >
                                            {attractionProviderType == 1 && (
                                                <div
                                                    style={{
                                                        background: 'blue',
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: 50
                                                    }}
                                                ></div>
                                            )}
                                        </div>
                                        <div>Per Request</div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 15
                                        }}
                                    >
                                        <div
                                            style={{
                                                border: '2px solid blue',
                                                width: 20,
                                                height: 20,
                                                borderRadius: 50,
                                                padding: 2,
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                setAttractionProviderType(2)
                                            }}
                                        >
                                            {attractionProviderType == 2 && (
                                                <div
                                                    style={{
                                                        background: 'blue',
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: 50
                                                    }}
                                                ></div>
                                            )}
                                        </div>
                                        <div>Fixed Times</div>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    gap: 10,
                                    marginTop: 30
                                }}
                            >
                                <span style={{ fontSize: 20, color: '#000' }}>days & hours</span>
                                <div className="dayHoursGrid">
                                    {dayHours && dayHours.length > 0 && dayHours.map((item, index) => (
                                        <div style={{ display: 'flex', gap: 5, fontSize: 14 }}>
                                            <div style={{ width: 50, border: '1px solid #ccc', display: 'flex', justifyContent: 'center', alignItems: "center" }}>{item.day}</div>
                                            <div>
                                                <input
                                                    type='text'
                                                    className='dayHoursInputBoxCss'
                                                    style={{ width: 100 }}
                                                    value={item.from_time}
                                                    onChange={e => {
                                                        changeDayHours(e, item, index, 'from')
                                                    }}
                                                    onFocus={() => {
                                                        setErrorMsg('')
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type='text'
                                                    className='dayHoursInputBoxCss'
                                                    style={{ width: 100 }}
                                                    value={item.to_time}
                                                    onChange={e => {
                                                        changeDayHours(e, item, index, 'to')
                                                    }}
                                                    onFocus={() => {
                                                        setErrorMsg('')
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div style={{ display: 'flex', gap: 80 }}>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: 10,
                                width: '60%'
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>What to remember</span>
                            <input
                                type='text'
                                className='InputBoxCss'
                                style={{ width: 600 }}
                                value={whatToRemember}
                                onChange={e => {
                                    setWhatToRemember(e.target.value)
                                }}
                                onFocus={() => {
                                    setErrorMsg('')
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: 10,
                                width: '60%',
                                marginTop: 30
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>Meeting Point (Description)</span>
                            <input
                                type='text'
                                className='InputBoxCss'
                                style={{ width: 600 }}
                                value={meetingPoint}
                                onChange={e => {
                                    setMeetingPoint(e.target.value)
                                }}
                                onFocus={() => {
                                    setErrorMsg('')
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: 10,
                                width: '60%',
                                marginTop: 30
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>Extra Information</span>
                            <input
                                type='text'
                                className='InputBoxCss'
                                style={{ width: 600 }}
                                value={extraInformation}
                                onChange={e => {
                                    setExtraInformation(e.target.value)
                                }}
                                onFocus={() => {
                                    setErrorMsg('')
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: 10,
                                width: '100%',
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>Duration</span>
                            <input
                                type='text'
                                className='InputBoxCss'
                                style={{ width: 350 }}
                                value={duration}
                                onChange={e => {
                                    setDuration(e.target.value)
                                }}
                                onFocus={() => {
                                    setErrorMsg('')
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: 10,
                                marginTop: 30
                            }}
                        >
                            <span style={{ fontSize: 20, color: '#000' }}>Cancellation Fee</span>
                            <textarea
                                aria-invalid='false'
                                id='txtDescription'
                                rows='5'
                                class='MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiOutlinedInput-inputMultiline InputBoxCss'
                                style={{ width: 350 }}
                                value={cancellationFee}
                                onChange={e => {
                                    setCancellationFee(e.target.value)
                                }}
                                onFocus={() => setErrorMsg('')}
                            />
                        </div>
                    </div>
                </div>



                <div style={{ marginTop: 50, marginBottom: 50, display: 'flex' }}>
                    <div>
                        <div style={{ fontSize: 18, fontWeight: 800, marginBottom: 15 }}>
                            PRICING AND TICKETS
                        </div>
                        <div style={{ padding: '0 50px', height: 'auto' }} className="selectLatLongDiv">
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        gap: 10,
                                        width: '60%'
                                    }}
                                >
                                    <span style={{ fontSize: 20, color: '#000' }}>Net Price</span>
                                    <input
                                        type='number'
                                        className='InputBoxCss'
                                        style={{ width: 350 }}
                                        value={netPrice}
                                        onChange={e => {
                                            setNetPrice(e.target.value)
                                        }}
                                        onFocus={() => {
                                            setErrorMsg('')
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        gap: 10,
                                        width: '100%',
                                        marginTop: 30
                                    }}
                                >
                                    <span style={{ fontSize: 18, color: '#000' }}>Traveler Prices</span>
                                    <div style={{ marginTop: 15, fontSize: 14, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 20 }}>
                                        <div style={{ width: 180 }}>Ticket type <br />(i.e Adult, kids, Group etc.)</div>
                                        <div style={{ width: 100 }}>Price</div>
                                        <div style={{ width: 120 }}>currency</div>
                                    </div>
                                    {ticketTypes && ticketTypes.length > 0 && ticketTypes.map((item, index) => (
                                        <div style={{ fontSize: 16, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 20 }}>
                                            <input
                                                type='text'
                                                className='InputBoxCss'
                                                style={{ width: 180 }}
                                                value={item.type}
                                                onChange={e => {
                                                    changeTicketTypes(e, item, index, 'type')
                                                }}
                                                onFocus={() => {
                                                    setErrorMsg('')
                                                }}
                                            />
                                            <input
                                                type='number'
                                                className='InputBoxCss'
                                                style={{ width: 100 }}
                                                value={item.price}
                                                onChange={e => {
                                                    changeTicketTypes(e, item, index, 'price')
                                                }}
                                                onFocus={() => {
                                                    setErrorMsg('')
                                                }}
                                            />

                                            <select className='InputBoxCss' style={{ width: 130, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', }} onChange={(e) => { changeTicketTypes(e, item, index, 'currency'); }}>
                                                <option value="">Currency</option>
                                                {currencyData && currencyData.length > 0 && currencyData.map((item1, index) => (
                                                    <option value={item1.id} selected={item.currency == item1.id && "selected"} >{item1.name}&nbsp;({item1.symbol})</option>
                                                ))}
                                            </select>
                                            {ticketTypes && ticketTypes.length > 1 &&
                                                <div style={{ width: 20, height: 20, cursor: 'pointer', marginLeft: -10, }} onClick={() => { deleteTicketType(item, index) }}>
                                                    <img src={DeleteIcon} style={{ width: 20, height: 20 }} alt="" />
                                                </div>
                                            }

                                        </div>
                                    ))}
                                    <div style={{ color: '#03c9d7', fontSize: 18, fontWeight: 600, marginTop: 20, cursor: 'pointer' }} className="addTicketType" onClick={() => { addTicketType() }}>
                                        + ADD TICKET TYPE
                                    </div>
                                </div>

                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ fontSize: 18, fontWeight: 800, marginBottom: 15 }}>
                            AVAILIBILITY
                        </div>
                        <div style={{ padding: '0 50px', height: 'auto' }} className="selectLatLongDiv">
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        gap: 10,
                                        width: '100%'
                                    }}
                                >
                                    <span style={{ fontSize: 20, color: '#000' }}>Confirmation</span>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 40
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 15
                                            }}
                                        >
                                            <div
                                                style={{
                                                    border: '2px solid blue',
                                                    width: 20,
                                                    height: 20,
                                                    borderRadius: 50,
                                                    padding: 2,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    setConfirmationType(0)
                                                }}
                                            >
                                                {confirmationType == 0 && (
                                                    <div
                                                        style={{
                                                            background: 'blue',
                                                            width: '100%',
                                                            height: '100%',
                                                            borderRadius: 50
                                                        }}
                                                    ></div>
                                                )}
                                            </div>
                                            <div>Instant Confirmation</div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 15
                                            }}
                                        >
                                            <div
                                                style={{
                                                    border: '2px solid blue',
                                                    width: 20,
                                                    height: 20,
                                                    borderRadius: 50,
                                                    padding: 2,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    setConfirmationType(1)
                                                }}
                                            >
                                                {confirmationType == 1 && (
                                                    <div
                                                        style={{
                                                            background: 'blue',
                                                            width: '100%',
                                                            height: '100%',
                                                            borderRadius: 50
                                                        }}
                                                    ></div>
                                                )}
                                            </div>
                                            <div>Request</div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        gap: 10,
                                        width: '100%',
                                        marginTop: 38
                                    }}
                                >
                                    <span style={{ fontSize: 18, color: '#000' }}>Booking information (local hero info)</span>
                                    <div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                gap: 5,
                                            }}
                                        >
                                            <span style={{ fontSize: 13, color: '#000' }}>Email</span>
                                            <input
                                                type='text'
                                                className='InputBoxCss'
                                                style={{ width: 350, fontSize: 14 }}
                                                value={localHeroEmail}
                                                onChange={e => {
                                                    setLocalHeroEmail(e.target.value)
                                                }}
                                                onFocus={() => {
                                                    setErrorMsg('')
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                gap: 5,
                                                marginTop: 10
                                            }}
                                        >
                                            <span style={{ fontSize: 13, color: '#000' }}>Address</span>
                                            <input
                                                type='text'
                                                className='InputBoxCss'
                                                style={{ width: 350, fontSize: 14 }}
                                                value={localHeroAddress}
                                                onChange={e => {
                                                    setLocalHeroAddress(e.target.value)
                                                }}
                                                onFocus={() => {
                                                    setErrorMsg('')
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                gap: 5,
                                                marginTop: 10
                                            }}
                                        >
                                            <span style={{ fontSize: 13, color: '#000' }}>Phone</span>
                                            <input
                                                type='number'
                                                className='InputBoxCss'
                                                style={{ width: 350, fontSize: 14 }}
                                                value={localHeroPhone}
                                                onChange={e => {
                                                    setLocalHeroPhone(e.target.value)
                                                }}
                                                onFocus={() => {
                                                    setErrorMsg('')
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                </div>


                {errorMsg != '' && (
                    <div
                        style={{
                            textAlign: 'center',
                            color: 'red',
                            fontSize: 16,
                            marginTop: 30
                        }}
                    >
                        {errorMsg}
                    </div>
                )}
                {fromStatus == 'create' ? (
                    <div
                        className='AddBtn'
                        onClick={() => {
                            createAttractionsFun()
                        }}
                    >
                        Add
                    </div>
                ) : (
                    <div
                        className='AddBtn'
                        onClick={() => {
                            editTripFun()
                        }}
                    >
                        Update
                    </div>
                )}
            </div>

            {showImagePopUp && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={() => { setShowImagePopUp(false) }}
                >
                    <div
                        style={{
                            background: '#fff',
                            borderRadius: 10,
                            padding: '15px'
                        }}
                    >
                        <SimpleFileUpload
                            apiKey={SimpleFileUploadAPIKey}
                            onSuccess={handleUpload}
                            width="350"
                        // onDrop={handleOnDrop}
                        />
                    </div>
                </div>
            )}


        </div>
    )
}

export default CreateAttraction
