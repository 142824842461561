import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Inject,
  Page,
  Toolbar,
} from "@syncfusion/ej2-react-grids"
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, { useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useLocation, useNavigate } from 'react-router-dom'
import SimpleFileUpload from 'react-simple-file-upload'
import { Header } from '../../components'
import BlueCheck from '../../data/blueCheck.png'
import CheckIcon from '../../data/check.png'
import DeleteIcon from '../../data/deleteIcon.png'
import { addDayTripGrid } from "../../data/dummy"
import DropDownArrow from '../../data/rightArrow.png'
import SelectedCheck from '../../data/selectedCheck.png'
import UnCheck from '../../data/unCheck.png'
import '../../pages/NewPages/Map/MapBox.css'
import { BASE_URL, get, post, put } from '../../Services/Calls'
import { mapboxglAccessToken, notify, SimpleFileUploadAPIKey } from '../../Services/Utils'
import './NewMain.css'


mapboxgl.accessToken = mapboxglAccessToken;

const CreateTrip = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const FilterOptions = {
    type: 'Excel'
  };

  const [tripName, setTripName] = useState('')
  const [imageName, setImageName] = useState('')
  const [description, setDescription] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [fromStatus, setFromStatus] = useState('')
  const [tripId, setTripId] = useState('')
  const [dayIdArray, setDayIdArray] = useState([])
  const dayIdArrayRef = useRef([])
  const [selectedRecords, setSelectedRecords] = useState([])
  const selectedRecordsRef = useRef([])
  const [showImagePopUp, setShowImagePopUp] = useState(false);
  const [personalized, setPersonalized] = useState(true);
  const [mostPopular, setMostPopular] = useState(false);
  const [showCountrySelect, setShowCountrySelect] = useState(false);
  const [showCurrencySelect, setShowCurrencySelect] = useState(false);
  const [showLanguageSelect, setShowLangaugeSelect] = useState(false);

  const [tripPrice, setTripPrice] = useState('')

  const gridRef = useRef(null);
  const toolbarOptions = ['Search'];
  // const selectionsettings = { persistSelection: true }
  const editOptions = {
    allowEditing: false,
    allowAdding: true,
    allowDeleting: true
  }

  const [daysData, setDaysData] = useState([]);
  const daysDataRef = useRef([]);
  const [countryData, setCountryData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [country, setCountry] = useState({});
  const [currency, setCurrency] = useState({});
  const [language, setLanguage] = useState("");
  const [languageSelectedArray, setLanguageSelectedArray] = useState([]);
  const [languageIdArray, setLanguageIdArray] = useState([]);
  const [highlight, setHighlight] = useState([""]);
  const [friendlyId, setFriendlyId] = useState('');

  useEffect(() => {
    (async () => {
      await getCountries();
      await getCurrency();
      await getLanguage();
    })();
  }, [])

  const getCountries = async () => {
    try {
      const response = await get(BASE_URL + '/countries?order=ASC');
      if (response && response.length > 0) {
        setCountryData(response);
      }
    }
    catch (ex) {
      alert(ex)
    }
  }

  const getCurrency = async () => {
    try {
      const response = await get(BASE_URL + "/currencies");
      if (response && response.length > 0) {
        setCurrencyData(response)
      }
    }
    catch (ex) {
      alert(ex)
    }
  };

  const getLanguage = async () => {
    try {
      const response = await get(BASE_URL + "/languages");
      if (response && response.length > 0) {
        setLanguageData(response);
      }
    }
    catch (ex) {
      alert(ex)
    }
  };

  const getDays = async () => {
    try {
      const response = await get(BASE_URL + "/days?order=DESC&take=999");
      if (response && response.results && response.results.length > 0) {
        let responseData = response.results
        let json = { selected: false }
        for (let i = 0; i < responseData.length; i++) {
          responseData[i] = { ...responseData[i], ...json }
        }
        // setPoiData(response.results)
        setDaysData(responseData)
        daysDataRef.current = responseData
      }
    }
    catch (ex) {
      alert(ex)
    }
  };

  const getDaysEdit = async (tempIdArray) => {
    try {
      const response = await get(BASE_URL + "/days?order=DESC&take=999");
      if (response && response.results && response.results.length > 0) {

        let tempDaysData = response.results
        if (tempIdArray && tempIdArray.length > 0) {
          for (let i = 0; i < tempDaysData.length; i++) {
            let status = false
            for (let j = 0; j < tempIdArray.length; j++) {
              if (tempDaysData[i].id == tempIdArray[j]) {
                status = true
              }
            }
            if (status) {
              tempDaysData[i] = { ...tempDaysData[i], ...{ selected: true } }
            }
            else {
              tempDaysData[i] = { ...tempDaysData[i], ...{ selected: false } }
            }
          }
        }

        setDaysData(tempDaysData)
        daysDataRef.current = tempDaysData
        setDayIdArray(tempIdArray)
        dayIdArrayRef.current = tempIdArray
      }
    }
    catch (ex) {
      alert(ex);
    }

  };

  useEffect(() => {
    if (location && location.state && location.state.from) {
      setFromStatus(location.state.from);
      if (location.state.from == 'create') {
        getDays();
      }
      if (location.state.data && location.state.data.id) {
        setTripName(location.state.data.name);
        setDescription(location.state.data.description);
        setImageName(location.state.data.image);
        setTripPrice(location.state.data.price);
        setCountry(location.state.data.country);
        setCurrency(location.state.data.currency);
        setTripId(location.state.data.id);
        setPersonalized(location.state.data.personalized);
        setMostPopular(location.state.data.most_popular);
        setHighlight(location.state.data.highlights);
        setFriendlyId(location.state.data.friendly_id);
        setLanguageSelectedArray(location.state.data.language);
        let tempLang = location.state.data.language;
        let tempLangIdArray = []
        for (let i = 0; i < tempLang.length; i++) {
          tempLangIdArray.push(tempLang[i].id)
        }
        setLanguageIdArray(tempLangIdArray)
        let tempDaySort = location.state.data.days_sort;
        let tempDayData = location.state.data.days;
        let tempDaysSelected = []
        for (let i = 0; i < tempDaySort.length; i++) {
          for (let j = 0; j < tempDayData.length; j++) {
            if (tempDaySort[i] == tempDayData[j].id) {
              tempDaysSelected.push(tempDayData[j])
              break;
            }
          }
        }
        setSelectedRecords(tempDaysSelected);

        selectedRecordsRef.current = tempDaysSelected;

        let tempDays = tempDaysSelected;
        let tempIdArray = [];
        for (let i = 0; i < tempDays.length; i++) {
          tempIdArray.push(tempDays[i].id);
        }
        setDayIdArray(tempIdArray);
        dayIdArrayRef.current = tempIdArray;
        getDaysEdit(tempIdArray);
      }
    }
    window.scrollTo(0, 0)
  }, [])

  async function createTripFun() {
    if (tripName == '' || tripName == undefined) {
      setErrorMsg('Please Enter Trip Name');
      return
    }
    if (description == '' || description == undefined) {
      setErrorMsg('Please Enter Trip Description');
      return
    }
    if (imageName && imageName != '') { }
    else {
      setErrorMsg('Please Upload Trip Image');
      return;
    }
    if (country && country.id) {

    }
    else {
      setErrorMsg('Please Select Country');
      return
    }

    if (currency && currency.id) {

    }
    else {
      setErrorMsg('Please Select Currency');
      return
    }

    if (tripPrice == '' || tripPrice == undefined) {
      setErrorMsg('Please Enter Trip Price')
      return
    }
    if (languageIdArray && languageIdArray.length > 0) { }
    else {
      setErrorMsg('Please Select Trip Language')
      return
    }

    try {
      let json = {
        name: tripName,
        active: true,
        description: description,
        image: imageName,
        price: +tripPrice,
        country_id: country.id,
        currency_id: currency.id,
        days_sort: dayIdArray,
        days: dayIdArray,
        show_on_languages: languageIdArray,
        personalized: personalized,
        most_popular: mostPopular,
        highlights: highlight.filter(element => element.trim() !== "")
      };
      const response = await post(BASE_URL + '/trips', json);
      if (response.status == 200) {
        notify(`Trip ${friendlyId} created successfully`);
        setTripName('');
        setDescription('');
        setImageName('');
        setCountry({});
        setCurrency({});
        setTripPrice("");
        setLanguageSelectedArray([]);
        setLanguageIdArray([]);
        navigate('/trips');
      }

    } catch (ex) {
      alert(ex)
    }
  }

  async function editTripFun() {
    if (tripName == '' || tripName == undefined) {
      setErrorMsg('Please Enter Trip Name');
      return
    }
    if (imageName && imageName == '' || imageName == undefined) {
      setErrorMsg('Please Upload Trip Image');
      return;
    }
    if (description == '' || description == undefined) {
      setErrorMsg('Please Enter Trip Description');
      return
    }
    if (country && country.id) {

    }
    else {
      setErrorMsg('Please Select Country');
      return;
    }

    if (currency && currency.id) {

    }
    else {
      setErrorMsg('Please Select Currency');
      return
    }

    if (tripPrice == '' || tripPrice == undefined) {
      setErrorMsg('Please Enter Trip Price');
      return;
    }

    try {
      let json = {
        name: tripName,
        active: true,
        description: description,
        image: imageName,
        price: +tripPrice,
        country_id: country.id,
        currency_id: currency.id,
        days_sort: dayIdArray,
        days: dayIdArray,
        show_on_languages: languageIdArray,
        personalized: personalized,
        most_popular: mostPopular,
        highlights: highlight.filter(element => element.trim() !== "")
      }

      const response = await put(BASE_URL + '/trips/' + tripId, json);
      if (response.status == 200) {
        notify(`Trip ${friendlyId} updated successfully`);
        setTripName('');
        setDescription('');
        setImageName('');
        setCountry({});
        setCurrency({});
        setTripPrice("");
        setTripId("");
        setLanguageSelectedArray([]);
        setLanguageIdArray([]);
        navigate('/trips');
      }
    } catch (ex) {
      alert(ex)
    }
  }

  function handleUpload(url) {
    setImageName(url);
    setShowImagePopUp(false);
    setErrorMsg("");
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // Drop outside the list
    }
    const reorderedItems = Array.from(selectedRecords);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setSelectedRecords(reorderedItems);
    selectedRecordsRef.current = reorderedItems;
    let tempIdArray = [];
    for (let i = 0; i < reorderedItems.length; i++) {
      tempIdArray.push(reorderedItems[i].id)
    }
    setDayIdArray(tempIdArray)
    dayIdArrayRef.current = tempIdArray
  };

  function selectLanguagesFun(item) {
    let tempLanguageSelectedArray = [...languageSelectedArray];
    let tempLanguageIdArray = [...languageIdArray];
    if (languageSelectedArray && languageSelectedArray.length > 0) {
      if (tempLanguageIdArray.includes(item.id)) {
        const index = tempLanguageIdArray.indexOf(item.id);
        tempLanguageSelectedArray.splice(index, 1);
        tempLanguageIdArray.splice(index, 1);
      }
      else {
        tempLanguageSelectedArray.push(item)
        tempLanguageIdArray.push(item.id)
      }
    }
    else {
      tempLanguageSelectedArray.push(item)
      tempLanguageIdArray.push(item.id)
    }
    setLanguageSelectedArray(tempLanguageSelectedArray)
    setLanguageIdArray(tempLanguageIdArray)
  }

  function selectRowFun(props) {
    if (props && props.selected) {
      let tempDayData = daysDataRef.current
      for (let i = 0; i < tempDayData.length; i++) {
        if (props.id == tempDayData[i].id) {
          tempDayData[i].selected = false
        }
      }
      setDaysData(tempDayData)
      daysDataRef.current = tempDayData;

      let tempSelectedRecord = [...selectedRecordsRef.current]
      for (let i = 0; i < tempSelectedRecord.length; i++) {
        if (props.id == tempSelectedRecord[i].id) {
          tempSelectedRecord.splice(i, 1)
        }
      }
      setSelectedRecords(tempSelectedRecord)
      selectedRecordsRef.current = tempSelectedRecord

      let tempDayIdArray = [...dayIdArrayRef.current]
      for (let i = 0; i < tempDayIdArray.length; i++) {
        if (props.id == tempDayIdArray[i]) {
          tempDayIdArray.splice(i, 1)
        }
      }
      setDayIdArray(tempDayIdArray)
      dayIdArrayRef.current = tempDayIdArray

    }
    else {
      let tempDayData = daysDataRef.current
      let tempSelectedRecord = [...selectedRecordsRef.current]
      let tempDayIdArray = [...dayIdArrayRef.current]
      for (let i = 0; i < tempDayData.length; i++) {
        if (props.id == tempDayData[i].id) {
          tempDayData[i].selected = true
          tempSelectedRecord = [...tempSelectedRecord, tempDayData[i]]
          tempDayIdArray = [...dayIdArrayRef.current, tempDayData[i].id]
        }
      }
      setSelectedRecords(tempSelectedRecord)
      selectedRecordsRef.current = tempSelectedRecord
      setDaysData(tempDayData)
      daysDataRef.current = tempDayData;
      setDayIdArray(tempDayIdArray)
      dayIdArrayRef.current = tempDayIdArray
    }
    gridRef.current.refresh();
  }

  const selectTableRows = (props) => (
    <div>
      {props && props.selected ?
        <img src={SelectedCheck} style={{ width: 18, height: 18 }} onClick={() => { selectRowFun(props) }} />
        :
        <img src={UnCheck} style={{ width: 18, height: 18 }} onClick={() => { selectRowFun(props) }} />
      }
    </div>
  );

  function addHighlightFun() {
    let tempHighlight = [...highlight]
    tempHighlight.push("")
    setHighlight(tempHighlight)
  }

  const handleInputChange = (index, value) => {
    let tempHighlight = [...highlight]
    for (let i = 0; i < tempHighlight.length; i++) {
      if (i == index) {
        tempHighlight[i] = value
      }
    }
    setHighlight(tempHighlight)
  };

  const deleteHighlightFun = (index) => {
    let tempHighlight = [...highlight]
    for (let i = 0; i < tempHighlight.length; i++) {
      if (i == index) {
        tempHighlight.splice(i, 1)
      }
    }
    setHighlight(tempHighlight)
  };

  return (
    <div className='m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl'>
      <div className='flex justify-between items-center h-20'>
        <Header
          category=''
          title={fromStatus == 'create' ? 'Add New Trip' : `Edit Trip ${friendlyId}`}
        />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }} className="mb-10 mr-20">
          {personalized ?
            <img src={BlueCheck} style={{ height: 25, width: 25, cursor: 'pointer' }} onClick={() => { setPersonalized(!personalized) }} />
            :
            <div style={{ borderRadius: 4, height: 25, width: 25, border: '2px solid #2D96F3', cursor: 'pointer' }} onClick={() => { setPersonalized(!personalized) }} >
            </div>
          }
          <div style={{ fontSize: 22, fontWeight: 'bold' }}>Personalized</div>
        </div>
      </div>
      <div className='countryFormMainDiv p-10'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 100
          }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Name</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 350 }}
                value={tripName}
                onChange={e => {
                  setTripName(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                marginTop: 40
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Description</span>
              <textarea
                aria-invalid='false'
                id='txtDescription'
                rows='5'
                class='MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiOutlinedInput-inputMultiline InputBoxCss'
                style={{ width: 600 }}
                value={description}
                onChange={e => {
                  setDescription(e.target.value)
                }}
                onFocus={() => setErrorMsg('')}
              />
            </div>
          </div>


          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '40%' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20, marginTop: 36, marginBottom: 35 }}>
              {mostPopular ?
                <img src={BlueCheck} style={{ height: 20, width: 20, cursor: 'pointer' }} onClick={() => { setMostPopular(!mostPopular) }} />
                :
                <div style={{ borderRadius: 4, height: 20, width: 20, border: '2px solid #2D96F3', cursor: 'pointer' }} onClick={() => { setMostPopular(!mostPopular) }} >
                </div>
              }
              <div style={{ fontSize: 20, fontWeight: '600' }}>Most Popular</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '40%' }}>
              <span style={{ fontSize: 20, color: '#000' }}>Image</span>
              {imageName && imageName != "" ?
                <>
                  <img src={imageName} style={{ width: 250, height: 150, borderRadius: 10 }} alt="" />
                  <div className="changeImg" onClick={() => { setShowImagePopUp(true) }}>
                    Change Image
                  </div>
                </>
                :
                <SimpleFileUpload
                  apiKey={SimpleFileUploadAPIKey}
                  onSuccess={handleUpload}
                  width="350"
                />
              }
            </div>
          </div>
        </div>

        <div className='selectLatLongDiv' style={{ height: 'auto' }}>
          <div>
            <div
              style={{
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>
                Country
              </span>

              <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowCountrySelect(!showCountrySelect); setErrorMsg("") }}>
                {country && country.id ?
                  <span style={{ color: '#000' }}>{country.name}</span>
                  :
                  <span style={{ color: 'gray' }}>Select Country</span>
                }
                <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showCountrySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
              </div>

              {showCountrySelect &&
                <ClickAwayListener onClickAway={() => { setShowCountrySelect(false) }}>
                  <div className="dropDownDiv">
                    {countryData && countryData.length > 0 && countryData.map((item, index) => (
                      <div className="countryDropDownItemDiv" onClick={() => { setCountry(item); setShowCountrySelect(false) }}>
                        {item.name}
                      </div>
                    ))}
                  </div>
                </ClickAwayListener>
              }
            </div>
          </div>
          <div>
            <div
              style={{
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>
                Currency
              </span>

              <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowCurrencySelect(!showCurrencySelect); setErrorMsg("") }}>
                {currency && currency.id ?
                  <span style={{ color: '#000' }}>{currency.name}&nbsp;({currency.symbol})</span>
                  :
                  <span style={{ color: 'gray' }}>Select Currency</span>
                }
                <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showCurrencySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
              </div>

              {showCurrencySelect &&
                <ClickAwayListener onClickAway={() => { setShowCurrencySelect(false) }}>
                  <div className="dropDownDiv">
                    {currencyData && currencyData.length > 0 && currencyData.map((item, index) => (
                      <div className="countryDropDownItemDiv" onClick={() => { setCurrency(item); setShowCurrencySelect(false) }}>
                        {item.name}&nbsp;({item.symbol})
                      </div>
                    ))}
                  </div>
                </ClickAwayListener>
              }

            </div>
          </div>
        </div>


        <div className='selectLatLongDiv' style={{ height: 'auto', marginBottom: 30 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: 10,
              width: '50%'
            }}
          >
            <span style={{ fontSize: 20, color: '#000' }}>
              Trip Price
            </span>
            <input
              type='number'
              className='InputBoxCss'
              style={{ width: 350 }}
              placeholder='Enter Trip Price'
              value={tripPrice}
              onChange={(e) => { setTripPrice(+e.target.value) }}
              onFocus={() => { setErrorMsg("") }}
            />
          </div>
          <div
            style={{
              width: '50%',
              marginTop: 30
            }}
          >
            <span style={{ fontSize: 20, color: '#000' }}>
              Language
            </span>

            <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowLangaugeSelect(!showLanguageSelect); setErrorMsg("") }}>
              {languageSelectedArray && languageSelectedArray.length > 0 ?
                <div style={{ width: '90%' }}>
                  {languageSelectedArray.map((item, index) => (
                    <>
                      <span style={{ color: '#000' }}>{item.name}</span>
                      {index !== languageSelectedArray.length - 1 && ','}&nbsp;
                    </>
                  ))}
                </div>
                :
                <span style={{ color: 'gray' }}>Select Language</span>
              }
              <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showLanguageSelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
            </div>


            {showLanguageSelect &&
              <ClickAwayListener onClickAway={() => { setShowLangaugeSelect(false) }}>
                <div className="dropDownDiv">
                  {/* <div className="countryDropDownItemDiv" onClick={() => { setLanguage(''); setShowLangaugeSelect(false) }}>
                      None
                    </div> */}
                  {languageData && languageData.length > 0 && languageData.map((item, index) => (
                    <div className={languageIdArray.includes(item.id) ? "countryDropDownItemActiveDiv" : "countryDropDownItemDiv"} onClick={() => { selectLanguagesFun(item) }}>
                      <span>{item.name}</span>
                      {languageIdArray.includes(item.id) &&
                        <img src={CheckIcon} style={{ width: 20, height: 20 }} alt="" />
                      }
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            }
          </div>
        </div>


        <div style={{ marginTop: 10, marginBottom: 50 }}>
          <div style={{ marginBottom: 20, fontSize: 20, color: '#000' }}>
            Highlights
          </div>
          {highlight && highlight.length > 0 && highlight.map((item, index) => (
            <div className="highlightDiv">
              <input
                type='text' className='InputBoxCss' style={{ width: '80%' }}
                id={item}
                value={highlight[index] || ''}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
              {item.length > 0 &&
                <img src={DeleteIcon} style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => { deleteHighlightFun(index) }} />
              }
            </div>
          ))}
          <div className="AddHighlightBtn" onClick={() => { addHighlightFun() }}>
            + Add Highlights
          </div>
        </div>


        <div style={{ marginTop: 10 }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 50 }}>
            <div style={{ width: '50%', }}>
              <div style={{ marginBottom: 20, fontSize: 20, color: '#000' }}>
                Select Days:
              </div>

              <GridComponent
                dataSource={daysData}
                enableHover={false}
                allowPaging
                pageSettings={{ pageCount: 3, pageSize: 12 }}
                // selectionSettings={selectionsettings}
                // toolbarClick={toolbarClick}
                toolbar={toolbarOptions}
                editSettings={editOptions}
                allowSorting
                filterSettings={FilterOptions}
                allowFiltering={true}
                // rowSelected={rowSelected}
                // rowDeselected={rowDeselected}
                ref={gridRef}
                style={{ width: '100%' }}
              >
                <ColumnsDirective>
                  <ColumnDirective template={selectTableRows} headerText="Select" width="40" />
                  {addDayTripGrid.map((item, index) => (
                    <ColumnDirective key={index} {...item} />
                  ))}
                </ColumnsDirective>
                <Inject services={[Page, Toolbar, Filter]} />
              </GridComponent>

            </div>

            <div style={{ width: '50%', }}>
              <div style={{ marginBottom: 20, fontSize: 20, color: '#000' }}>
                Order Selected days (Drag to order):
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <div style={{ minHeight: selectedRecords && selectedRecords.length > 0 ? 'auto' : 150, width: '100%', border: '1px solid #e0e0e0', }}>
                        {selectedRecordsRef && selectedRecordsRef.current && selectedRecordsRef.current.length > 0 ?
                          <div>
                            {selectedRecordsRef && selectedRecordsRef.current.map((item, index) => (

                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div style={{ padding: '15px 15px', borderBottom: snapshot.isDragging ? '1px solid blue' : '1px solid #e0e0e0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 40, fontSize: 13, cursor: 'default' }}>
                                      <div style={{ width: 15, height: 15, border: '2.5px solid black', cursor: 'move' }}>
                                      </div>
                                      {/* <div style={{ width: 250, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {item.id}
                                      </div> */}
                                      <div style={{ width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {item.friendly_id || ""}
                                      </div>
                                      <div style={{ width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {item.name || ""}
                                      </div>
                                      <div style={{ width: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {item.description || ""}
                                      </div>
                                    </div>

                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                          :
                          <div style={{ height: 150, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20, color: '#908d8d' }}>
                            No Data
                          </div>
                        }
                      </div>

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>


        </div>






        {errorMsg != '' && (
          <div
            style={{
              textAlign: 'center',
              color: 'red',
              fontSize: 16,
              marginTop: 30
            }}
          >
            {errorMsg}
          </div>
        )}
        {fromStatus == 'create' ? (
          <div
            className='AddBtn'
            onClick={() => {
              createTripFun()
            }}
          >
            Add
          </div>
        ) : (
          <div
            className='AddBtn'
            onClick={() => {
              editTripFun()
            }}
          >
            Update Trip {friendlyId}
          </div>
        )}
      </div>

      {showImagePopUp && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={() => { setShowImagePopUp(false) }}
        >
          <div
            style={{
              background: '#fff',
              borderRadius: 10,
              padding: '15px'
            }}
          >
            <SimpleFileUpload
              apiKey={SimpleFileUploadAPIKey}
              onSuccess={handleUpload}
              width="350"
            // onDrop={handleOnDrop}
            />
          </div>
        </div>
      )}


    </div>
  )
}

export default CreateTrip
