import React, { useEffect, useState, useRef } from 'react'
import './NewMain.css'
import { BASE_URL, get, post, put } from '../../Services/Calls'
import { Header } from '../../components'
import { useLocation, useNavigate } from 'react-router-dom'
import SimpleFileUpload from 'react-simple-file-upload'
import DropDownArrow from '../../data/rightArrow.png'
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { ToastContainer, toast } from 'react-toastify';
import { ContextMenuSettings } from '@syncfusion/ej2/filemanager'
import mapboxgl from 'mapbox-gl'
import { notify } from '../../Services/Utils'

const CreateHotels = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [map, setMap] = useState(null);
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11');
  const [mapMode, setMapMode] = useState('1');
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [hotelName, setHotelName] = useState('');
  const [overnightCity, setOvernightCity] = useState('');
  const [siteLink, setSiteLink] = useState('');
  const [lowSeasonPriceDouble, setLowSeasonPriceDouble] = useState(0);
  const [lowSeasonPriceSingle, setLowSeasonPriceSingle] = useState(0);
  const [highSeasonPriceDouble, setHighSeasonPriceDouble] = useState(0);
  const [highSeasonPriceSingle, setHighSeasonPriceSingle] = useState(0);
  const [currencyData, setCurrencyData] = useState([]);
  const [currency, setCurrency] = useState({});

  const [hotelLevel, setHotelLevel] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [fromStatus, setFromStatus] = useState('');
  const [hotelId, setHotelId] = useState('');

  const [showCountrySelect, setShowCountrySelect] = useState(false);
  const [showCurrencySelect, setShowCurrencySelect] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [country, setCountry] = useState({});


  useEffect(() => {
    getCountries();
    getCurrency();
  }, [])

  useEffect(() => {
    // Create a map instance
    mapRef.current = new mapboxgl.Map({
      container: 'map', // Specify the container ID
      style: mapStyle, // Specify the map style
      center: [32, 34], // Specify the starting center
      zoom: 3, // Specify the starting zoom
    })
    mapRef.current.on('load', () => {
      setMap(mapRef.current)
    })
    mapRef.current.on('style.load', function () {
      mapRef.current.on('click', function (e) {
        var coordinates = e.lngLat
        new mapboxgl.Popup()
        if (markerRef.current != null) {
          markerRef.current.remove()
        }
        markerRef.current = new mapboxgl.Marker()
          .setLngLat(coordinates)
          // .setHTML('' + coordinates)
          .addTo(mapRef.current)

        setLatitude(coordinates.lat)
        setLongitude(coordinates.lng)
        mapRef.current.flyTo({ center: [coordinates.lng, coordinates.lat], zoom: 7 });
      })
    })
    // Clean up the map instance when the component unmounts
    return () => mapRef.current.remove()
  }, [mapStyle, mapMode]) // The empty dependency array ensures this useEffect runs only once

  const getCountries = async () => {
    try {
      const response = await get(BASE_URL + '/countries?order=ASC');
      if (response && response.length > 0) {
        setCountryData(response);
      }
    }
    catch (ex) {
      alert(ex)
    }
  }

  const getCurrency = async () => {
    try {
      const response = await get(BASE_URL + "/currencies");
      if (response && response.length > 0) {
        setCurrencyData(response);
      }
    }
    catch (ex) {
      alert(ex)
    }
  };

  useEffect(() => {
    if (location && location.state && location.state.from) {
      setFromStatus(location.state.from)
      if (location.state.data && location.state.data.id) {
        setHotelName(location.state.data.hotel_name);
        setOvernightCity(location.state.data.overnight_city);
        setSiteLink(location.state.data.site_link);
        setCountry(location.state.data.country);
        setHotelLevel(location.state.data.hotel_level_basic);
        setLowSeasonPriceDouble(location.state.data.low_season_price_double);
        setLowSeasonPriceSingle(location.state.data.low_season_price_single);
        setHighSeasonPriceDouble(location.state.data.high_season_price_double);
        setHighSeasonPriceSingle(location.state.data.high_season_price_single);
        setHotelId(location.state.data.id);
        setCurrency(location.state.data.currency);
        setLatitude(location.state.data.latitude);
        setLongitude(location.state.data.longitude);
      }
    }
    window.scrollTo(0, 0);
  }, [])

  async function createHotel() {
    if (hotelName == '' || hotelName == undefined) {
      setErrorMsg('Please Enter Hotel Name.')
      return
    }

    if (overnightCity == '' || overnightCity == undefined) {
      setErrorMsg('Please Enter Overnight City.')
      return
    }

    if (siteLink == '' || siteLink == undefined) {
      setErrorMsg('Please Enter Site Link.')
      return
    }

    if (country && country.id) {

    }
    else {
      setErrorMsg('Please Select Country.');
      return
    }

    if (currency && currency.id) {

    }
    else {
      setErrorMsg('Please Select Currency.')
      return
    }

    if (lowSeasonPriceDouble == '' || lowSeasonPriceDouble == undefined) {
      setErrorMsg('Please Enter Low Season Double Room Price.');
      return
    }
    if (lowSeasonPriceSingle == '' || lowSeasonPriceSingle == undefined) {
      setErrorMsg('Please Enter Low Season Single Room Price.');
      return
    }
    if (highSeasonPriceDouble == '' || highSeasonPriceDouble == undefined) {
      setErrorMsg('Please Enter High Season Double Room Price.');
      return
    }
    if (highSeasonPriceSingle == '' || highSeasonPriceSingle == undefined) {
      setErrorMsg('Please Enter High Season Single Room Price.');
      return
    }
    if (latitude == '' || latitude == undefined) {
      setErrorMsg('Please Enter Latitude.')
      return
    }
    if (longitude == '' || longitude == undefined) {
      setErrorMsg('Please Enter Longitude.')
      return
    }

    try {
      const json = {
        country: country.id,
        hotel_name: hotelName,
        overnight_city: overnightCity,
        site_link: siteLink,
        hotel_level_basic: hotelLevel,
        low_season_price_double: parseInt(lowSeasonPriceDouble),
        low_season_price_single: parseInt(lowSeasonPriceSingle),
        high_season_price_double: parseInt(highSeasonPriceDouble),
        high_season_price_single: parseInt(highSeasonPriceSingle),
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        currency: currency.id,
      };
      const response = await post(BASE_URL + '/hotels-prices-list', json)
      if (response) {
        notify('Hotel created successfully');
        setHotelName('');
        setOvernightCity('');
        setSiteLink('');
        setCountry({});
        setHotelLevel(true);
        setLowSeasonPriceDouble(0);
        setHighSeasonPriceSingle(0);
        setLongitude(0);
        setLatitude(0);
        navigate('/HotelPriceList');
        setCurrency({});
      }
    } catch (ex) {
      alert(ex)
    }
  }

  async function editHotel() {
    if (hotelName == '' || hotelName == undefined) {
      setErrorMsg('Please Enter Hotel Name.')
      return
    }
    if (overnightCity == '' || overnightCity == undefined) {
      setErrorMsg('Please Enter Overnight City.')
      return
    }
    if (siteLink == '' || siteLink == undefined) {
      setErrorMsg('Please Enter Site Link.')
      return
    }

    if (country && country.id) {

    }
    else {
      setErrorMsg('Please Select Country.')
      return
    }

    if (currency && currency.id) {

    }
    else {
      setErrorMsg('Please Select Currency.')
      return
    }

    if (lowSeasonPriceDouble == '' || lowSeasonPriceDouble == undefined) {
      setErrorMsg('Please Enter Low Season Double Room Price.')
      return
    }
    if (lowSeasonPriceSingle == '' || lowSeasonPriceSingle == undefined) {
      setErrorMsg('Please Enter Low Season Single Room Price.')
      return
    }
    if (highSeasonPriceDouble == '' || highSeasonPriceDouble == undefined) {
      setErrorMsg('Please Enter High Season Double Room Price.')
      return
    }
    if (highSeasonPriceSingle == '' || highSeasonPriceSingle == undefined) {
      setErrorMsg('Please Enter High Season Single Room Price.')
      return
    }
    if (latitude == '' || latitude == undefined) {
      setErrorMsg('Please Enter Latitude.')
      return
    }
    if (longitude == '' || longitude == undefined) {
      setErrorMsg('Please Enter Longitude.')
      return
    }

    try {
      const json = {
        country: country.id,
        hotel_name: hotelName,
        overnight_city: overnightCity,
        site_link: siteLink,
        hotel_level_basic: hotelLevel,
        low_season_price_double: parseInt(lowSeasonPriceDouble),
        low_season_price_single: parseInt(lowSeasonPriceSingle),
        high_season_price_double: parseInt(highSeasonPriceDouble),
        high_season_price_single: parseInt(highSeasonPriceSingle),
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        currency: currency.id,
      };
      const response = await put(BASE_URL + '/hotels-prices-list/' + hotelId, json)
      if (response) {
        notify('Hotel updated successfully');
        setHotelName('');
        setOvernightCity('');
        setSiteLink('');
        setCountry({});
        setCurrency({});
        setHotelLevel(true);
        setLowSeasonPriceDouble(0);
        setHighSeasonPriceSingle(0);
        setLongitude(0);
        setLatitude(0);
        navigate('/HotelPriceList');
      }
    }
    catch (ex) {
      alert(ex);
    }
  }

  return (
    <div className='m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl'>
      <div className='flex justify-between items-center h-20'>
        <Header
          category=''
          title={fromStatus == 'create' ? 'Add New Hotel' : 'Edit Hotel'}
        />
      </div>
      <div className='countryFormMainDiv p-10'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start'
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Hotel Name</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 350 }}
                value={hotelName}
                onChange={e => {
                  setHotelName(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>

          </div>


        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 40
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Overnight City</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 350 }}
                value={overnightCity}
                onChange={e => {
                  setOvernightCity(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>

          </div>
          <div style={{ width: '40%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '100%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Site Link</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 350 }}
                value={siteLink}
                onChange={e => {
                  setSiteLink(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>

          </div>


        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: 25
          }}
        >
          <div
            style={{
              // display: 'flex',
              // justifyContent: 'center',
              // alignItems: 'flex-start',
              // flexDirection: 'column',
              // gap: 10,
              width: '60%'
            }}
          >
            <span style={{ fontSize: 20, color: '#000' }}>Country</span>

            <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowCountrySelect(!showCountrySelect); setErrorMsg("") }}>
              {country && country.id ?
                <span style={{ color: '#000' }}>{country.name}</span>
                :
                <span style={{ color: 'gray' }}>Select Country</span>
              }
              <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showCountrySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
            </div>

            {showCountrySelect &&
              <ClickAwayListener onClickAway={() => { setShowCountrySelect(false) }}>
                <div className="dropDownDiv">
                  {countryData && countryData.length > 0 && countryData.map((item, index) => (
                    <div className="countryDropDownItemDiv" onClick={() => { setCountry(item); setShowCountrySelect(false) }}>
                      {item.name}
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            }

          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: 10,
              width: '40%'
            }}
          >
            <span style={{ fontSize: 20, color: '#000' }}>Hotel Level</span>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 40
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 15
                }}
              >
                <div
                  style={{
                    border: '2px solid blue',
                    width: 20,
                    height: 20,
                    borderRadius: 50,
                    padding: 2,
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setHotelLevel(true)
                  }}
                >
                  {hotelLevel && (
                    <div
                      style={{
                        background: 'blue',
                        width: '100%',
                        height: '100%',
                        borderRadius: 50
                      }}
                    ></div>
                  )}
                </div>
                <div>Basic</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 15
                }}
              >
                <div
                  style={{
                    border: '2px solid blue',
                    width: 20,
                    height: 20,
                    borderRadius: 50,
                    padding: 2,
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setHotelLevel(false)
                  }}
                >
                  {!hotelLevel && (
                    <div
                      style={{
                        background: 'blue',
                        width: '100%',
                        height: '100%',
                        borderRadius: 50
                      }}
                    ></div>
                  )}
                </div>
                <div>Upgraded</div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: 25
          }}
        >
          <div
            style={{
              // display: 'flex',
              // justifyContent: 'center',
              // alignItems: 'flex-start',
              // flexDirection: 'column',
              // gap: 10,
              width: '60%'
            }}
          >
            <div
              style={{
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>
                Currency
              </span>

              <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowCurrencySelect(!showCurrencySelect); setErrorMsg("") }}>
                {currency && currency.id ?
                  <span style={{ color: '#000' }}>{currency.name}&nbsp;({currency.symbol})</span>
                  :
                  <span style={{ color: 'gray' }}>Select Currency</span>
                }
                <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showCurrencySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
              </div>

              {showCurrencySelect &&
                <ClickAwayListener onClickAway={() => { setShowCurrencySelect(false) }}>
                  <div className="dropDownDiv">
                    {currencyData && currencyData.length > 0 && currencyData.map((item, index) => (
                      <div className="countryDropDownItemDiv" onClick={() => { setCurrency(item); setShowCurrencySelect(false) }}>
                        {item.name}&nbsp;({item.symbol})
                      </div>
                    ))}
                  </div>
                </ClickAwayListener>
              }

            </div>

          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: 10,
              width: '40%'
            }}
          >
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 40
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '100%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Low season price double</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 50 }}
                value={lowSeasonPriceDouble}
                onChange={e => {
                  setLowSeasonPriceDouble(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>

          </div>
          <div style={{ width: '40%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '100%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Low season price single</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 50 }}
                value={lowSeasonPriceSingle}
                onChange={e => {
                  setLowSeasonPriceSingle(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>

          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 40
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '100%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>High season price double</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 50 }}
                value={highSeasonPriceDouble}
                onChange={e => {
                  setHighSeasonPriceDouble(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>
          </div>
          <div style={{ width: '40%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '100%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>High season price single</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 50 }}
                value={highSeasonPriceSingle}
                onChange={e => {
                  setHighSeasonPriceSingle(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 40
          }}
        >
          {/* <div style={{ width: '60%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '100%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>Location</span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 130 }}
                value={latitude}
                onChange={e => {
                  setLatitude(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 130 }}
                value={longitude}
                onChange={e => {
                  setLongitude(e.target.value)
                }}
                onFocus={() => {
                  setErrorMsg('')
                }}
              />
            </div>
          </div> */}

          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 10,
                width: '60%'
              }}
            >
              <span style={{ fontSize: 20, color: '#000' }}>
                Latitude, Longitude
              </span>
              <input
                type='text'
                className='InputBoxCss'
                style={{ width: 350 }}
                placeholder='32.00, 34.00'
                value={latitude != '' ? latitude + "," + longitude : ''}
                onChange={e => {
                  let latlng = e.target.value;
                  if (e.target.value === '') {
                    setLatitude('');
                    setLongitude('');
                    if (markerRef.current != null) {
                      markerRef.current.remove();
                    }
                  } else {
                    setLatitude(latlng);
                    if (markerRef.current != null) {
                      markerRef.current.remove();
                    }
                  }
                  latlng = latlng.trim();
                  latlng = latlng.split(',');
                  if (latlng.length === 2) {
                    setLatitude(latlng[0].trim());
                    setLongitude(latlng[1].trim());
                    markerRef.current = new mapboxgl.Marker()
                      .setLngLat([latlng[1].trim(), latlng[0].trim()])
                      .addTo(mapRef.current);
                    map.flyTo({ center: [latlng[1].trim(), latlng[0].trim()], zoom: 7 });
                  }
                }}
                onFocus={() => setErrorMsg('')}
              />
            </div>
            <div className='outerMapContainer'>
              <div id='map' style={{ width: '100%', height: '100%' }}></div>
            </div>
          </div>
        </div>

        {errorMsg != '' && (
          <div
            style={{
              textAlign: 'center',
              color: 'red',
              fontSize: 16,
              marginTop: 30
            }}
          >
            {errorMsg}
          </div>
        )}
        {fromStatus == 'create' ? (
          <div
            className='AddBtn'
            onClick={() => {
              createHotel()
            }}
          >
            Add
          </div>
        ) : (
          <div
            className='AddBtn'
            onClick={() => {
              editHotel()
            }}
          >
            Edit
          </div>
        )}
      </div>
    </div>
  )
}

export default CreateHotels
